class ValidacionArray {
  static validarArray (data) {
    const { WhsCode, WhsName, Guia } = data.filter(x => x.WhsCode)[0]
    for (const el of data) {
      if (!(el.WhsCode || el.WhsName)) {
        el.WhsCode = WhsCode
        el.WhsName = WhsName
        el.Guia = Guia
      }
    }
    const yup = require('yup')
    const schema = yup.object().shape({
      RowId: yup.number().required(),
      EnvioId: yup.number().required().label('Número de envío'),
      Guia: yup.string().required().label('Número de guía'),
      Date: yup.date().nullable().label('Fecha de envío'),
      WhsCode: yup.string().nullable().label('Código de bodega'),
      WhsName: yup.string().nullable().label('Nombre de bodega'),
      ItemCode: yup.string().required().label('Código de producto'),
      ItemName: yup.string().required().label('Nombre de producto'),
      CodeBars: yup.string().nullable().label('Fecha de envío'),
      AgrupId: yup.number().nullable().label('Agrupador'),
      IdItemCode: yup.number().required().label('Id de producto'),
      NumLote: yup.string().required().label('Número de lote'),
      Quantity: yup.number().required().label('Cantidad'),
      FecVen: yup.date().required().label('Fecha de vencimiento'),
      Status: yup.boolean().required()
    })
    const arraySchema = yup.array().of(schema)
    return arraySchema.validate(data, {
      // abortEarly: false,
      strict: false
    })
  }
}

module.exports = ValidacionArray
