/* eslint-disable no-useless-return */
import { defineStore } from 'pinia'
import EntradaMercancia from '../services/entradaMercancia'
import ValidacionArray from '../schemas/EntMercancia.schema'
const _EntradaMercancia = new EntradaMercancia()
export const useCrear = defineStore('Crear', {
  state: () => {
    return {
      EntMercancia: [],
      Apartados: []
    }
  },
  getters: {
    _entMercancia: (state) => state.EntMercancia,
    _apartados: (state) => state.Apartados
  },
  actions: {
        registartEntMercancia () {
          return new Promise((resolve, reject) => {
          const data = JSON.parse(localStorage.getItem('confirmarRecepcion')).filter(x => x.Status === true)
            localStorage.removeItem('confirmarRecepcion')
            ValidacionArray.validarArray(data)
              .then(async (validatedData) => {
                resolve(await _EntradaMercancia.registrar({ EntMercancia: validatedData, Apartados: this.Apartados }))
              })
              .catch(error => {
                reject(error.errors)
              })
          })
    },
    getOvDtId (DocId) {
      return new Promise((resolve, reject) => {
        _EntradaMercancia.getOvDtId(DocId).then(({ data }) => {
          resolve(data)
        }).catch((err) => {
          console.log(err)
          // eslint-disable-next-line prefer-promise-reject-errors
          reject([])
        })
      })
    },
    async confirmarApartados (payload) {
      this.Apartados = payload
      return this.registartEntMercancia()
    }
  }
})
