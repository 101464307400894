import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_LOGISTICA + '/control-envio'
export default class ControlEnvioService {
  listar (params) {
    return http.get(`${baseUrl}/listar`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  ver (id) {
    return http.get(`${baseUrl}/ver/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  searchArticulo (data) {
    return http.get(`${baseUrl}/buscar-articulo/${data.query}`, {
      params: {
        AgrupId: data.AgrupId
      },
      headers: {
        loading: true
      }
    })
  }
}
