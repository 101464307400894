import { defineStore } from 'pinia'
import ControlEnvioService from '../services/controlEnvio'
const _ControlEnvioService = new ControlEnvioService()
export const useVer = defineStore('ver', {
  state: () => {
    return {
      envio: [],
      id: null
    }
  },
  getters: {
    _envio: (state) => {
      return state.envio.sort((a, b) => a.ItemCode - b.ItemCode).sort((a, b) => a.Status - b.Status)
    },
    _id: (state) => state.id
  },
  actions: {
    ver () {
      _ControlEnvioService.ver(this.id).then(({ data }) => {
        this.envio = data
      })
    },
    confirmarRecepcion () {
      return new Promise((resolve, reject) => {
        const data = this.envio
        const confirmarRecepcion = this.envio.filter((item) => item.Status === true)
        if (confirmarRecepcion.length === 0) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ message: 'Debe seleccionar al menos un producto' })
        } else {
          localStorage.setItem('confirmarRecepcion', JSON.stringify(data))
          resolve()
        }
      })
    },
    modificarRow (data) {
      for (const row of this.envio) {
        if (row.RowId === data.RowId) {
          row.FecVen = data.FecVen
          row.ItemCode = data.ItemCode
          row.ItemName = data.ItemName
          row.Justificacion = data.Justificacion
          row.NumLote = data.NumLote
          row.Quantity = data.Quantity
          row.Status = true
          row.AgrupId = data.AgrupId
          row.IdItemCode = data.IdItemCode
        } else {
          // alert('No se puede modificar el articulo agregado manualmente, Elimine y vuelva a agregar')
        }
      }
    },
    searchArticulo (data) {
      return new Promise((resolve) => {
        _ControlEnvioService.searchArticulo(data).then(({ data }) => {
          resolve(data)
        })
      })
    },
    duplicateRow (data) {
      data.RowId = Math.max(...this.envio.map(item => item.RowId))
      this.envio = [...this.envio, data]
    },
    addRow (data) {
      const { EnvioId } = this.envio[0]
      data.RowId = Math.max(...this.envio.map(item => item.RowId))
      data.EnvioId = EnvioId
      this.envio = [...this.envio, data]
    }
  }
})
