import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_LOGISTICA + '/entrada-mercancia'
export default class ControlEnvioService {
  registrar (data) {
    return http.post(`${baseUrl}/registrar`, data, {
      headers: {
        loading: true
      }
    })
  }

  getOvDtId (DocId) {
    return http.get(`${baseUrl}/detalle-ov/${ DocId }`, {
      headers: {
        loading: true
      }
    })
  }

  listar (params) {
    return http.get(`${baseUrl}/listar`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
