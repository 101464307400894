<template>
  <div>
    <Toast />
    <div class="flex justify-between p-4 bg-white ">
      <div class="flex flex-col">
        <span class="text-xs font-bold">N° Envio</span>
        <span class="text-xs">{{ header.EnvioId }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-xs font-bold">Guia</span>
        <span class="text-xs">{{ header.Guia }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-xs font-bold">Fecha</span>
        <span class="text-xs">{{ header.DocDate }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-xs font-bold">Bodega Destino</span>
        <span class="text-xs">{{ header.WhsName }}</span>
      </div>
    </div>
  </div>
  <div class="w-full">
    <Table :rows="storeVer.envio"/>
  </div>
  <div>
    <div class="flex justify-end p-4 bg-white gap-2">
      <ConfirmPopup/>
      <Button label="Cancelar"  severity="danger" @click="onCancelEntrega()" />
      <Button label="Confirmar" @click="onConfirmarRecepcion()" />
    </div>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router'
import { defineAsyncComponent, onMounted, computed } from 'vue'
import { useVer } from '../../../store/ver.store'
import { useCrear } from '../../../store/crear.store'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
const toast = useToast()
const confirm = useConfirm()
const Table = defineAsyncComponent(() => import('../../components/ver/table.vue'))
const route = useRoute()
const router = useRouter()
const storeVer = useVer()
const storeCrear = useCrear()
const header = computed(() => {
  const temp = storeVer._envio[0]
  return {
    EnvioId: temp ? temp.EnvioId : '',
    Guia: temp ? temp.Guia : '',
    DocDate: temp ? temp.Date : '',
    WhsName: temp ? temp.WhsName : ''
  }
})
onMounted(() => {
  storeVer.id = route.params.EnvioId
  storeVer.ver()
})
const onConfirmarRecepcion = () => {
  storeVer.confirmarRecepcion().then(() => {
    confirm.require({
      target: event.currentTarget,
      message: 'Agregar Medicamento Apartados?',
      icon: 'pi pi-exclamation-triangle',
      position: 'left',
      accept: () => {
        router.push({ name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica.apartado' })
      },
      reject: () => {
        storeCrear.registartEntMercancia().then(() => {
          toast.add({ severity: 'success', summary: 'Registro exitoso', life: 3000 })
          router.push({ name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica' })
        }).catch(error => {
          console.log('registartEntMercancia', error)
          for (const errorElement of error) {
            console.log('errorElement', errorElement)
            toast.add({ severity: 'error', summary: errorElement, life: 3000 })
          }
        })
      }
    })
  }).catch(e => {
    toast.add({ severity: 'error', summary: e.message, life: 3000 })
  })
}
const onCancelEntrega = () => {
  router.go(-1)
  localStorage.removeItem('confirmarRecepcion')
}
</script>
